import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

// loader starts
const customLoader = `<style>
.loader3 {
  width:150px;
  height:150px;
  display:inline-block;
  padding:0px;
  text-align:left;
}
.loader3 span {
  position:absolute;
  display:inline-block;
  width:150px;
  height:150px;
  border-radius:100%;
  background: #FF0000;
  -webkit-animation:loader3 1.5s linear infinite;
  animation:loader3 1.5s linear infinite;
}
.loader3 span:last-child {
  animation-delay:-0.9s;
  -webkit-animation-delay:-0.9s;
}
@keyframes loader3 {
  0% {transform: scale(0, 0);opacity:0.8;}
  100% {transform: scale(1, 1);opacity:0;}
}
@-webkit-keyframes loader3 {
  0% {-webkit-transform: scale(0, 0);opacity:0.8;}
  100% {-webkit-transform: scale(1, 1);opacity:0;}
}
.text-center{
  display: flex;
  align-items:center;
  justify-content: center;
  margin-top:200px;
}
</style>
<div class="text-center">
<div class="loader3">
    <span></span>
    <span></span>
</div>
</div>
<p align="center">Loading...</p>`;
// loader ends
const customError = "<h1 align='center' style='color: #FF0000; margin-top: 200px;'>Oops! App isn't working right now.</h1>";
const data = {
  loaders: {
    customLoader,
  },
  props: {
    user: { fname: "Jane", lname: "John" },
  },
  errors: {
    customError,
  },
};

const routes = constructRoutes(microfrontendLayout, data);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
